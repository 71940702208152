<template>
    <div v-if="product?.productType === 'combine' && product?.variantGroups.length > 1">
        <div class="text-base">
            {{ attributeTranslations.color }}
            <span v-if="selectedVariant?.colorName" class="capitalize">: {{ selectedVariant?.colorName }}</span>
        </div>
        <div class="flex flex-wrap gap-2 mt-1">
            <div
                    v-for="color in product?.variantGroups"
                    :key="color?.colorCode"
                    :class="['border overflow-hidden', color?.colorCode === selectedVariant?.colorCode ? 'border-primary' : 'border-transparent']"
                    @click="setSelectedVariant(color);"
            >
                <NuxtImg
                    :src="getImage(color?.thumbnail)"
                    :alt="color?.colorCode"
                    v-if="color?.url && color?.url !== 'null'"
                    class="h-28 aspect-2/3 object-contain cursor-pointer"
                />
            </div>
        </div>
    </div>
</template>

<script setup>
const {
    product,
    selectedVariant,
    attributeTranslations,
    setSelectedVariant
} = await useProduct()

</script>

<style scoped>

</style>