<template>
  <div>
    <div class="mt-6">
      <div class="mx-auto max-w-4xl">
        <dl class="mt-10 font-medium">
          <Disclosure
            as="div"
            v-for="title in titles"
            :key="title?.id"
            class="pt-6"
            v-slot="{ open }"
            :defaultOpen="title.id === 1"
          >
            <dt v-if="title.isShow">
              <DisclosureButton class="flex w-full items-start justify-between text-left">
                <span class="text-lg">{{ title.name }}</span>
                <span class="ml-6 flex h-7 items-center text-gray-400 hover:text-gray-500">
                  <Minus v-if="open" class="h-5 w-5" aria-hidden="true"/>
                  <Plus v-else class="h-5 w-5" aria-hidden="true" />
                </span>
              </DisclosureButton>
            </dt>
            <DisclosurePanel as="dd" class="mt-2 max-h-64 overflow-y-auto text-base" v-if="title.isShow">
              <!-- Ürün Açıklaması -->
              <div v-if="title.id === 1 && title.isShow" class="text-primary">
                <div v-if="selectedVariant?.description" v-html="selectedVariant.description"></div>
                <p v-else>{{ $t('listing.no-product-description') }}</p>
              </div>

              <!-- Ürün Değerlendirmeleri -->
              <FeaturesReview v-if="title.id === 2 && title.isShow"/>

              <!-- Ürün Soru & Cevapları -->
              <FeaturesQuestion v-if="title.id === 3 && title.isShow"/>
              
              <!-- İade Açıklaması -->
              <div v-if="title.id === 4 && title.isShow" class="text-primary" v-html="getLocaleString(productConfig?.refund_description)"></div>

              <!-- Manken Bilgisi -->
              <FeaturesModelInfo :models="selectedVariant.model" v-if="title.id === 5 && title.isShow" class="text-primary" />

            </DisclosurePanel>
          </Disclosure>
        </dl>
      </div>
    </div>
  </div>
</template>

<script setup>
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";
import {Minus, Plus} from "lucide-vue-next"

const {t} = useI18n()
const { showOnlyB2C } = useHelper();

const storeConfig = useStoreConfig()
const {productConfig} = storeToRefs(storeConfig)

const {
  product,
  selectedVariant,
  installments,
  productQuestions,
  isModelExists
} = await useProduct();

const titles = ref([
  { id: 1, name: t('listing.feature-disclosure-titles.product-description'), isShow: true },
  { id: 5, name: t('listing.feature-disclosure-titles.model-info'), isShow: isModelExists.value},
  { id: 2, name: t('listing.feature-disclosure-titles.comments'), isShow: showOnlyB2C.value },
  { id: 3, name: t('listing.feature-disclosure-titles.qa'), isShow: showOnlyB2C.value },
  { id: 4, name: t('listing.feature-disclosure-titles.shipping-and-return'), isShow: showOnlyB2C.value && getLocaleString(productConfig.value?.refund_description)},
]);

const activeTitle = ref(titles.value[0]);
</script>
