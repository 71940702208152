import { default as _91slug_93HCm3aIOJL4Meta } from "/home/forge/airlifestore.com/pages/[slug].vue?macro=true";
import { default as addresswzhkFHpA7XMeta } from "/home/forge/airlifestore.com/pages/account/address.vue?macro=true";
import { default as _91id_93GImjUoTv5qMeta } from "/home/forge/airlifestore.com/pages/account/orders/[id].vue?macro=true";
import { default as _91id_93cUcyoem63yMeta } from "/home/forge/airlifestore.com/pages/account/orders/cancel/[id].vue?macro=true";
import { default as indexnSwDxrKmphMeta } from "/home/forge/airlifestore.com/pages/account/orders/index.vue?macro=true";
import { default as reviewW3MSXB56sUMeta } from "/home/forge/airlifestore.com/pages/account/review.vue?macro=true";
import { default as _91id_93PaqYFKc3aLMeta } from "/home/forge/airlifestore.com/pages/account/statement/detail/[id].vue?macro=true";
import { default as indexByLwkRmPH1Meta } from "/home/forge/airlifestore.com/pages/account/statement/index.vue?macro=true";
import { default as userYHf7MJoj9mMeta } from "/home/forge/airlifestore.com/pages/account/user.vue?macro=true";
import { default as wishlist8C4BraaWBPMeta } from "/home/forge/airlifestore.com/pages/account/wishlist.vue?macro=true";
import { default as cartuXBzN3ZMQ3Meta } from "/home/forge/airlifestore.com/pages/cart.vue?macro=true";
import { default as indexzhkoWXOmzCMeta } from "/home/forge/airlifestore.com/pages/checkout/index.vue?macro=true";
import { default as paymentIr1qL2b2eoMeta } from "/home/forge/airlifestore.com/pages/checkout/payment.vue?macro=true";
import { default as summary_46clientCmCo22TKYxMeta } from "/home/forge/airlifestore.com/pages/checkout/summary.client.vue?macro=true";

let _createClientPage
async function createClientPage(loader) {
  _createClientPage ||= await import("/home/forge/airlifestore.com/node_modules/nuxt/dist/components/runtime/client-component").then(r => r.createClientPage)
  return _createClientPage(loader);
}
import { default as requestBUf0FmqS4VMeta } from "/home/forge/airlifestore.com/pages/customer/request.vue?macro=true";
import { default as successUphuGryOxYMeta } from "/home/forge/airlifestore.com/pages/customer/success.vue?macro=true";
import { default as iletisim77LzII59adMeta } from "/home/forge/airlifestore.com/pages/iletisim.vue?macro=true";
import { default as indexZH9RrtykTGMeta } from "/home/forge/airlifestore.com/pages/index.vue?macro=true";
import { default as loginnaFsXjDegOMeta } from "/home/forge/airlifestore.com/pages/login.vue?macro=true";
import { default as offerNlZjwm4dQxMeta } from "/home/forge/airlifestore.com/pages/offer.vue?macro=true";
import { default as statusQSaiTy1vJ9Meta } from "/home/forge/airlifestore.com/pages/order/status.vue?macro=true";
import { default as track1D0H0R8t7BMeta } from "/home/forge/airlifestore.com/pages/order/track.vue?macro=true";
import { default as forgot5idvyKe0rEMeta } from "/home/forge/airlifestore.com/pages/password/forgot.vue?macro=true";
import { default as resetGBbqDHzMvgMeta } from "/home/forge/airlifestore.com/pages/password/reset.vue?macro=true";
import { default as payment_45notificationPP2iNWLVAxMeta } from "/home/forge/airlifestore.com/pages/payment-notification.vue?macro=true";
import { default as summary5x2CRLXxOtMeta } from "/home/forge/airlifestore.com/pages/payment/summary.vue?macro=true";
import { default as registerrCvfOPLY6kMeta } from "/home/forge/airlifestore.com/pages/register.vue?macro=true";
import { default as searchIF5zWTocLtMeta } from "/home/forge/airlifestore.com/pages/search.vue?macro=true";
import { default as locationsnKw2fmRYyuMeta } from "/home/forge/airlifestore.com/modules/locationsPage/pages/locations.vue?macro=true";
import { default as component_45stub1K29tF9kz4Meta } from "/home/forge/airlifestore.com/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stub1K29tF9kz4 } from "/home/forge/airlifestore.com/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "slug___tr",
    path: "/:slug()",
    meta: _91slug_93HCm3aIOJL4Meta || {},
    component: () => import("/home/forge/airlifestore.com/pages/[slug].vue")
  },
  {
    name: "account-address___tr",
    path: "/account/address",
    meta: addresswzhkFHpA7XMeta || {},
    component: () => import("/home/forge/airlifestore.com/pages/account/address.vue")
  },
  {
    name: "account-orders-id___tr",
    path: "/account/orders/:id()",
    meta: _91id_93GImjUoTv5qMeta || {},
    component: () => import("/home/forge/airlifestore.com/pages/account/orders/[id].vue")
  },
  {
    name: "account-orders-cancel-id___tr",
    path: "/account/orders/cancel/:id()",
    meta: _91id_93cUcyoem63yMeta || {},
    component: () => import("/home/forge/airlifestore.com/pages/account/orders/cancel/[id].vue")
  },
  {
    name: "account-orders___tr",
    path: "/account/orders",
    meta: indexnSwDxrKmphMeta || {},
    component: () => import("/home/forge/airlifestore.com/pages/account/orders/index.vue")
  },
  {
    name: "account-review___tr",
    path: "/account/review",
    meta: reviewW3MSXB56sUMeta || {},
    component: () => import("/home/forge/airlifestore.com/pages/account/review.vue")
  },
  {
    name: "account-statement-detail-id___tr",
    path: "/account/statement/detail/:id()",
    meta: _91id_93PaqYFKc3aLMeta || {},
    component: () => import("/home/forge/airlifestore.com/pages/account/statement/detail/[id].vue")
  },
  {
    name: "account-statement___tr",
    path: "/account/statement",
    meta: indexByLwkRmPH1Meta || {},
    component: () => import("/home/forge/airlifestore.com/pages/account/statement/index.vue")
  },
  {
    name: "account-user___tr",
    path: "/account/user",
    meta: userYHf7MJoj9mMeta || {},
    component: () => import("/home/forge/airlifestore.com/pages/account/user.vue")
  },
  {
    name: "account-wishlist___tr",
    path: "/account/wishlist",
    meta: wishlist8C4BraaWBPMeta || {},
    component: () => import("/home/forge/airlifestore.com/pages/account/wishlist.vue")
  },
  {
    name: "cart___tr",
    path: "/cart",
    component: () => import("/home/forge/airlifestore.com/pages/cart.vue")
  },
  {
    name: "checkout___tr",
    path: "/checkout",
    meta: indexzhkoWXOmzCMeta || {},
    component: () => import("/home/forge/airlifestore.com/pages/checkout/index.vue")
  },
  {
    name: "checkout-payment___tr",
    path: "/checkout/payment",
    meta: paymentIr1qL2b2eoMeta || {},
    component: () => import("/home/forge/airlifestore.com/pages/checkout/payment.vue")
  },
  {
    name: "checkout-summary___tr",
    path: "/checkout/summary",
    component: () => createClientPage(() => import("/home/forge/airlifestore.com/pages/checkout/summary.client.vue"))
  },
  {
    name: "customer-request___tr",
    path: "/customer/request",
    component: () => import("/home/forge/airlifestore.com/pages/customer/request.vue")
  },
  {
    name: "customer-success___tr",
    path: "/customer/success",
    component: () => import("/home/forge/airlifestore.com/pages/customer/success.vue")
  },
  {
    name: "iletisim___tr",
    path: "/iletisim",
    component: () => import("/home/forge/airlifestore.com/pages/iletisim.vue")
  },
  {
    name: "index___tr",
    path: "/",
    meta: indexZH9RrtykTGMeta || {},
    component: () => import("/home/forge/airlifestore.com/pages/index.vue")
  },
  {
    name: "login___tr",
    path: "/login",
    meta: loginnaFsXjDegOMeta || {},
    component: () => import("/home/forge/airlifestore.com/pages/login.vue")
  },
  {
    name: "offer___tr",
    path: "/offer",
    meta: offerNlZjwm4dQxMeta || {},
    component: () => import("/home/forge/airlifestore.com/pages/offer.vue")
  },
  {
    name: "order-status___tr",
    path: "/order/status",
    component: () => import("/home/forge/airlifestore.com/pages/order/status.vue")
  },
  {
    name: "order-track___tr",
    path: "/order/track",
    component: () => import("/home/forge/airlifestore.com/pages/order/track.vue")
  },
  {
    name: "password-forgot___tr",
    path: "/password/forgot",
    meta: forgot5idvyKe0rEMeta || {},
    component: () => import("/home/forge/airlifestore.com/pages/password/forgot.vue")
  },
  {
    name: "password-reset___tr",
    path: "/password/reset",
    meta: resetGBbqDHzMvgMeta || {},
    component: () => import("/home/forge/airlifestore.com/pages/password/reset.vue")
  },
  {
    name: "payment-notification___tr",
    path: "/payment-notification",
    component: () => import("/home/forge/airlifestore.com/pages/payment-notification.vue")
  },
  {
    name: "payment-summary___tr",
    path: "/payment/summary",
    component: () => import("/home/forge/airlifestore.com/pages/payment/summary.vue")
  },
  {
    name: "register___tr",
    path: "/register",
    meta: registerrCvfOPLY6kMeta || {},
    component: () => import("/home/forge/airlifestore.com/pages/register.vue")
  },
  {
    name: "search___tr",
    path: "/search",
    meta: searchIF5zWTocLtMeta || {},
    component: () => import("/home/forge/airlifestore.com/pages/search.vue")
  },
  {
    name: "contact___tr",
    path: "/magazalarimiz",
    component: () => import("/home/forge/airlifestore.com/modules/locationsPage/pages/locations.vue")
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/38140-mens-sweatshirt",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/4505-womens-sweatshirt",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/4369-womens-trousers",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/6406-mens-shorts",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/5949-mens-shorts",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/37598-mens-shorts",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/37604-mens-shorts",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/4007-mens-sweatshirt",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/107515-mens-sweatshirt",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/107523-mens-sweatshirt",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/107551-mens-sweatshirt",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/6390-mens-trousers",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/6484-womens-shorts",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/36698-womens-shorts",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/37458-womens-shorts",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/6546-womens-sweatshirt",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/107451-womens-sweatshirt",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/107483-womens-sweatshirt",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/37490-womens-tights",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/6396-womens-trousers",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/37438-womens-trousers",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/37687-mens-tracksuit",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/37829-mens-tracksuit",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/37496-mens-capri",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/37522-mens-capri",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/37414-mens-shorts",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/37358-mens-shorts",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/37566-mens-shorts",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/38108-mens-sweatshirt",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/38176-mens-sweatshirt",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/38146-mens-sweatshirt",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/37643-mens-tracksuit",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/37709-mens-tracksuit",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/37795-mens-tracksuit",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/276-mens-trousers",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/290-mens-trousers",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/434-mens-trousers",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/907-mens-trousers",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/1511-mens-trousers",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/4903-mens-trousers",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/5197-mens-trousers",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/5223-mens-trousers",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/4521-womens-sweatshirt",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/38010-womens-sweatshirt",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/38270-womens-sweatshirt",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/4173-womens-trousers",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/4319-womens-trousers",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/38044-womens-trousers",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/37992-womens-trousers",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/159077-mens-sweatshirt",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/159073-mens-sweatshirt",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/159185-womens-tracksuit",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/159221-womens-tracksuit",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/159245-womens-tracksuit",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/159261-womens-tracksuit",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/159281-womens-tracksuit",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/159161-womens-trousers",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/159173-womens-trousers",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/159297-womens-trousers",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/159317-womens-trousers",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/1179-mens-shorts",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/1255-mens-shorts",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/159013-mens-shorts",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/158981-mens-shorts",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/225373-mens-shorts",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/225393-mens-shorts",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/225585-mens-shorts",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/38218-mens-sweatshirt",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/38188-mens-sweatshirt",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/159101-mens-sweatshirt",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/229496-mens-sweatshirt",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/2583-mens-tights",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/5973-mens-tracksuit",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/6374-mens-tracksuit",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/105633-mens-tracksuit",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/159053-mens-tracksuit",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/159017-mens-tracksuit",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/244-mens-trousers",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/334-mens-trousers",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/630-mens-trousers",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/642-mens-trousers",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/772-mens-trousers",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/4931-mens-trousers",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/5007-mens-trousers",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/6585-mens-trousers",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/5049-mens-trousers",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/5069-mens-trousers",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/5317-mens-trousers",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/5339-mens-trousers",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/6024-mens-trousers",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/6058-mens-trousers",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/6080-mens-trousers",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/37570-mens-trousers",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/158961-mens-trousers",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/158945-mens-trousers",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/159109-mens-trousers",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/159125-mens-trousers",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/159141-mens-trousers",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/456-mens-trousers",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/2541-womens-tights",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/2573-womens-tights",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/36741-womens-tights",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/36734-womens-tights",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/38223-womens-tights",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/36710-womens-tights",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/4841-womens-tracksuit",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/734-mens-trousers",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/108039-mens-trousers",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/108023-mens-trousers",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/107791-mens-trousers",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/107771-mens-trousers",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/3792-womens-bodysuit",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/38267-womens-shorts",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/1493-womens-tights",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/38257-womens-tights",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/1551-mens-tracksuit",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/5071-mens-trousers",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/5301-mens-trousers",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/4417-womens-shorts",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/158941-mens-capri",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/1119-mens-shorts",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/1203-mens-shorts",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/1259-mens-shorts",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/1650-mens-shorts",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/38102-mens-sweatshirt",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/5911-mens-tracksuit",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/37667-mens-tracksuit",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/37675-mens-tracksuit",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/37741-mens-tracksuit",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/37755-mens-tracksuit",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/258-mens-trousers",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/382-mens-trousers",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/466-mens-trousers",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/662-mens-trousers",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/794-mens-trousers",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/879-mens-trousers",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/893-mens-trousers",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/1541-mens-trousers",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/4965-mens-trousers",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/5097-mens-trousers",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/5111-mens-trousers",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/5135-mens-trousers",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/5141-mens-trousers",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/5157-mens-trousers",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/95294-mens-trousers",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/6114-mens-trousers",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/6150-mens-trousers",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/6162-mens-trousers",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/6176-mens-trousers",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/6204-mens-trousers",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/38307-mens-trousers",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/38322-mens-trousers",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/107739-mens-trousers",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/107979-mens-trousers",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/107951-mens-trousers",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/107915-mens-trousers",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/107899-mens-trousers",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/107879-mens-trousers",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/107839-mens-trousers",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/107831-mens-trousers",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/107675-mens-trousers",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/107651-mens-trousers",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/107567-mens-trousers",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/107579-mens-trousers",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/107619-mens-trousers",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/4081-womens-tracksuit",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/1105-mens-shorts",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/1610-mens-shorts",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/198-mens-tracksuit",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/220-mens-tracksuit",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/37773-mens-tracksuit",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/5273-mens-trousers",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/107999-mens-trousers",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/107755-mens-trousers",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/107703-mens-trousers",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/6500-womens-shorts",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/36666-womens-sweatshirt",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/3720-womens-bikini-suit",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/3744-womens-bodysuit",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/107455-womens-sweatshirt",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/107487-womens-sweatshirt",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/3810-womens-bodysuit",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/6554-mens-tights",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/5943-mens-tights",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/4819-mens-tracksuit",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/5979-mens-tracksuit",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/4869-mens-trousers",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/4877-mens-trousers",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/3786-womens-bodysuit",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/6414-womens-shorts",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/6422-womens-shorts",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/37285-womens-sweatshirt",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/37226-womens-sweatshirt",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/6560-womens-tights",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/2647-womens-tracksuit",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/37390-womens-trousers",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/38245-womens-shorts",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/38238-womens-tights",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/38070-womens-sweatshirt",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/38295-womens-tights",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/38078-womens-trousers",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/3774-womens-bodysuit",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/38030-womens-sweatshirt",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/36686-womens-shorts",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/38251-womens-tights",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/3732-womens-bikini-suit",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/3750-womens-bodysuit",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/3780-womens-bodysuit",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/3798-womens-bodysuit",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/38226-womens-shorts",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/38237-womens-shorts",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/36748-womens-tights",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/2529-womens-tights",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/2533-womens-tights",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/38231-womens-tights",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/225455-erkek-antrenman-atlet",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/225475-erkek-antrenman-atlet",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/36990-erkek-antrenman-tisort",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/37245-erkek-antrenman-tisort",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/37038-erkek-antrenman-tisort",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/37178-erkek-antrenman-tisort",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/37342-erkek-kapusonlu-kosucu-tisort",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/37117-erkek-kapusonlu-sporcu-tisort",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/225451-erkek-oversize-antrenman-atlet",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/225413-erkek-oversize-kolsuz-tisort",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/225435-erkek-oversize-kolsuz-tisort",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/225175-erkek-oversize-tisort",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/225191-erkek-oversize-tisort",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/225199-erkek-oversize-tisort",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/224956-erkek-oversize-tisort",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/224988-erkek-oversize-tisort",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/225004-erkek-oversize-tisort",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/225032-erkek-oversize-tisort",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/225076-erkek-oversize-tisort",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/36905-erkek-pamuklu-tisort",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/36890-erkek-pamuklu-tisort",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/36886-erkek-pamuklu-tisort",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/37014-erkek-sporcu-tisort",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/37293-erkek-sporcu-tisort",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/37130-erkek-sporcu-tisort",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/37078-erkek-sporcu-tisort",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/37202-erkek-sporcu-tisort",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/150288-erkek-sporcu-tisort",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/158693-erkek-sporcu-tisort",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/158713-erkek-sporcu-tisort",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/158749-erkek-sporcu-tisort",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/158765-erkek-sporcu-tisort",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/158789-erkek-sporcu-tisort",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/158809-erkek-sporcu-tisort",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/38138-erkek-tam-boy-fermuarli-sweatshirt",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/225093-kadin-crop-oversize-tisort",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/225143-kadin-crop-oversize-tisort",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/225163-kadin-crop-oversize-tisort",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/225219-kadin-crop-oversize-tisort",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/225336-kadin-crop-tisort",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/225344-kadin-crop-tisort",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/4355-kadin-esofman-alti",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/4514-kadin-kapusonlu-sweatshirt",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/158845-kadin-sporcu-tisort",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/158873-kadin-sporcu-tisort",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/158877-kadin-sporcu-tisort",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/158905-kadin-sporcu-tisort",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/225268-kadin-tisort",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/225308-kadin-tisort",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/5949-erkek-dokuma-antrenman-sort",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/37614-erkek-dokuma-kosucu-sortu",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/37594-erkek-dokuma-performans-sortu",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/41328-erkek-dokuma-sporcu-esofmant-alti",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/6402-erkek-dokuma-sporcu-sort",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/264310-erkek-esofman-alti",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/4007-erkek-kapusonlu-fermuarli-sweatshirt",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/107503-erkek-ruzgarlik-sweatshirt",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/107523-erkek-ruzgarlik-sweatshirt",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/107551-erkek-ruzgarlik-sweatshirt",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/36702-kadin-antrenman-sortu",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/6396-kadin-dokuma-sporcu-esofman-alti",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/6532-kadin-kapusonlu-sweatshirt",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/225248-kadin-oversize-tisort",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/107447-kadin-ruzgarlik-sweatshirt",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/107479-kadin-ruzgarlik-sweatshirt",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/6482-kadin-sort",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/37458-kadin-sort",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/37489-kadin-tayt",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/37454-kadin-yunlu-esofman-alti",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/37695-erkek-kapusonlu-fermuarli-esofman-takimi",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/37829-erkek-sporcu-esofman-takimi",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/4906-erkek-esofman-alti",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/37501-erkek-kapri",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/37641-erkek-kapusonlu-fermuarli-esofman-takimi",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/38108-erkek-kapusonlu-fermuarli-sweatshirt",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/38180-erkek-kapusonlu-fermuarli-sweatshirt",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/262-erkek-likrali-esofman-alti",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/292-erkek-likrali-esofman-alti",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/225495-erkek-likrali-esofman-alti",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/438-erkek-seritli-esofman-alti",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/37534-erkek-sort",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/901-erkek-sporcu-esofman-alti",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/5195-erkek-sporcu-esofman-alti",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/5215-erkek-sporcu-esofman-alti",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/37526-erkek-sporcu-kapri",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/37413-erkek-sporcu-sortu",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/37378-erkek-sporcu-sortu",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/37709-erkek-tam-boy-fermuarli-esofman-takimi",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/37801-erkek-tam-boy-fermuarli-esofman-takimi",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/38156-erkek-tam-boy-fermuarli-sweatshirt",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/37992-kadin-esofman-alti",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/4319-kadin-gunluk-esofman-alti",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/4527-kadin-likrali-sweatshirt",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/4165-kadin-sporcu-esofman-alti",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/38021-kadin-sweatshirt",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/38270-kadin-sweatshirt",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/38057-kadin-yunlu-esofman-alti",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/159085-erkek-flamli-sweatshirt",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/159073-erkek-flamli-sweatshirt",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/159161-kadin-esofman-alti",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/159173-kadin-esofman-alti",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/159293-kadin-esofman-alti",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/159321-kadin-esofman-alti",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/159189-kadin-esofman-takimi",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/159217-kadin-esofman-takimi",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/159245-kadin-esofman-takimi",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/159253-kadin-esofman-takimi",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/159269-kadin-esofman-takimi",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/225393-erkek-antrenman-oversize-sort",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/5327-erkek-esofman-alti",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/5345-erkek-esofman-alti",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/225540-erkek-esofman-alti",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/264338-erkek-esofman-alti",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/264350-erkek-esofman-alti",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/305793-erkek-esofman-alti",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/244-erkek-flamli-esofman-alti",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/333-erkek-flamli-esofman-alti",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/630-erkek-flamli-esofman-alti",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/641-erkek-flamli-esofman-alti",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/776-erkek-flamli-esofman-alti",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/4938-erkek-flamli-esofman-alti",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/5010-erkek-flamli-esofman-alti",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/5041-erkek-flamli-esofman-alti",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/5063-erkek-flamli-esofman-alti",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/6084-erkek-flamli-esofman-alti",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/37577-erkek-flamli-esofman-alti",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/158973-erkek-flamli-esofman-alti",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/5963-erkek-flamli-esofman-takimi",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/159041-erkek-flamli-esofman-takimi",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/159017-erkek-flamli-esofman-takimi",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/1169-erkek-flamli-sort",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/1254-erkek-flamli-sort",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/159013-erkek-flamli-sort",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/158997-erkek-flamli-sort",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/159101-erkek-flamli-sweatshirt",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/105633-erkek-kapusonlu-fermuarli-esofman-takimi",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/38217-erkek-kapusonlu-fermuarli-sweatshirt",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/38194-erkek-kapusonlu-fermuarli-sweatshirt",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/158949-erkek-nano-polyester-esofman-alti",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/159109-erkek-nano-polyester-esofman-alti",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/159121-erkek-nano-polyester-esofman-alti",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/159137-erkek-nano-polyester-esofman-alti",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/225597-erkek-oversize-antranman-sort",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/225369-erkek-oversize-antrenman-sort",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/225605-erkek-oversize-esofman-alti",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/225617-erkek-oversize-esofman-alti",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/229496-erkek-oversize-sweatshirt",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/6382-erkek-pamuklu-esofman-takimi",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/456-erkek-sporcu-esofman-alti",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/6028-erkek-sporcu-esofman-alti",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/6056-erkek-sporcu-esofman-alti",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/2585-erkek-sporcu-tayti",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/38223-kadin-antrenman-tayt",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/5035-kadin-esofman-alti",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/4842-kadin-kapusonlu-esofman-takimi",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/36736-kadin-kosucu-tayt",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/2571-kadin-plates-tayt",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/36741-kadin-plates-tayt",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/36713-kadin-plates-tayt",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/2543-kadin-yoga-tayt",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/108051-erkek-esofman-alti",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/108027-erkek-esofman-alti",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/107799-erkek-esofman-alti",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/107787-erkek-esofman-alti",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/738-erkek-pamuklu-esofman-alti",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/1496-kadin-antrenman-tayti",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/38263-kadin-kosucu-sortu",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/38257-kadin-kosucu-tayt",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/3792-kadin-mayo",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/5079-erkek-desenli-esofman-alti",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/5314-erkek-desenli-esofman-alti",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/1556-erkek-kosucu-esofman-takimi",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/4429-kadin-antrenman-sort",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/468-erkek-antrenman-esofman-alti",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/6152-erkek-antrenman-esofman-alti",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/1273-erkek-desenli-sort",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/5105-erkek-esofman-alti",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/5133-erkek-esofman-alti",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/6119-erkek-esofman-alti",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/6192-erkek-esofman-alti",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/6198-erkek-esofman-alti",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/38301-erkek-esofman-alti",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/38315-erkek-esofman-alti",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/225532-erkek-esofman-alti",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/225560-erkek-esofman-alti",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/305607-erkek-esofman-alti",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/305761-erkek-esofman-alti",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/305833-erkek-esofman-alti",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/305829-erkek-esofman-alti",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/107727-erkek-esofman-alti",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/107979-erkek-esofman-alti",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/107935-erkek-esofman-alti",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/107931-erkek-esofman-alti",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/107899-erkek-esofman-alti",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/107879-erkek-esofman-alti",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/107859-erkek-esofman-alti",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/107835-erkek-esofman-alti",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/107683-erkek-esofman-alti",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/107647-erkek-esofman-alti",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/107564-erkek-esofman-alti",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/107593-erkek-esofman-alti",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/107611-erkek-esofman-alti",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/158929-erkek-kapri",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/37681-erkek-kapusonlu-fermuarli-esofman-takimi",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/37761-erkek-kapusonlu-fermuarli-esofman-takimi",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/38090-erkek-kapusonlu-fermuarli-sweatshirt",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/252-erkek-klasik-esofman-alti",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/6164-erkek-kosucu-esofman-alti",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/405-erkek-likrali-esofman-alti",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/668-erkek-likrali-esofman-alti",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/801-erkek-likrali-esofman-alti",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/880-erkek-likrali-esofman-alti",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/893-erkek-likrali-esofman-alti",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/1532-erkek-likrali-esofman-alti",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/41320-erkek-likrali-esofman-alti",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/5107-erkek-likrali-esofman-alti",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/5147-erkek-likrali-esofman-alti",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/5170-erkek-likrali-esofman-alti",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/1115-erkek-likrali-sort",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/1205-erkek-likrali-sort",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/1654-erkek-likrali-sort",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/5250-erkek-sporcu-esofman-alti",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/5923-erkek-sporcu-esofman-takimi",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/37741-erkek-sporcu-esofman-takimi",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/37662-erkek-yunlu-esofman-takimi",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/4081-kadin-likrali-esofman-takimi",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/5269-erkek-esofman-alti",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/107991-erkek-esofman-alti",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/107747-erkek-esofman-alti",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/107711-erkek-esofman-alti",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/108055-erkek-esofman-alti",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/37771-erkek-kapusonlu-fermuarli-esofman-takimi",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/1113-erkek-likrali-sort",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/1613-erkek-likrali-sort",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/188-erkek-sporcu-esofman-takimi",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/220-erkek-sporcu-esofman-takimi",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/6504-kadin-pamuklu-sporcu-sort",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/36670-kadin-kapusonlu-sweatshirt",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/3720-kadin-bikini-takimi",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/3744-kadin-mayo",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/107467-kadin-ruzgarlik-sweatshirt",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/107495-kadin-ruzgarlik-sweatshirt",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/3804-kadin-mayo",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/5931-erkek-antrenman-tayt",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/4870-erkek-desenli-esofman-alti",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/4875-erkek-desenli-esofman-alti",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/4819-erkek-kapusonlu-esofman-takimi",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/5983-erkek-sporcu-esofman-takimi",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/6556-erkek-sporcu-tayt",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/6566-kadin-antrenman-tayt",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/6423-kadin-dokuma-antrenman-sort",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/6414-kadin-dokuma-sporcu-sort",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/3786-kadin-mayo",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/2651-kadin-pamuklu-esofman-takimi",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/37273-kadin-yunlu-basic-sweatshirt",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/37393-kadin-yunlu-esofman-alti",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/37228-kadin-yunlu-sweatshirt",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/38243-kadin-sporcu-sortu",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/38239-kadin-sporcu-tayti",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/38078-kadin-esofman-alti",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/38074-kadin-sweatshirt",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/38297-kadin-tayt",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/3774-kadin-mayo",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/38032-kadin-yunlu-sweatshirt",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/36684-kadin-kosucu-sortu",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/38253-kadin-yoga-tayt",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/264266-erkek-oversize-esofman-alti",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/264278-erkek-oversize-sweatshirt",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/38227-kadin-antrenman-sort",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/3727-kadin-bikini-takimi",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/38235-kadin-kosucu-sortu",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/36748-kadin-kosucu-tayt",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/2529-kadin-kosucu-tayti",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/38231-kadin-kosucu-tayti",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/3750-kadin-mayo",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/3780-kadin-mayo",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tr/3798-kadin-mayo",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/2531-kadin-sporcu-tayt",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/unisex",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/erkek-ayakkabi-172",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/erkek-sweatshirtler-146",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/kadin-sort-140",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/kadin-sweatshirt-146",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/erkek-eldivenler-177",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/kadin-havlular-170",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/kadin-elbiseler-157",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/erkek-fermuarlilar-149",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/erkek-kapusonlular-148",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/erkek-sort-140",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/kadin-bikini-165",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/kadin-mayolar-166",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/kadin-giyim-127",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/kadin-kapusonlular-148",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/erkek-giyim-127",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/kadin-atletler-138",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/kadin-ayakkabi-172",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/erkek-kapriler-141",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/erkek-basic-takimlar-152",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/kadin-spor-ayakkabi-173",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/erkek-deniz-sortu-167",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/kadin-esofman-alti-143",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/erkek-polo-tisortler-129",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/kadin-basic-takimlar-152",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/kadin-sort-139",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/erkek-esofman-takimi-151",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/kadin-tisortler-128",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/erkek-gunluk-ayakkabi-174",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/37553-erkek-kapri",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/kadin-gunluk-ayakkabi-174",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/kadin-esofman-takimlari-151",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/37558-erkek-kapri",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/kadin-etekler-156",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/38283-kadin-sweatshirt",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/erkek-fermuarli-takimlar-154",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/37510-erkek-kapri",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/36710-kadin-plates-tayt",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/225288-kadin-tisort",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/37566-erkek-sort",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/erkek-plaj-aksesuarlari-164",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/37496-erkek-kapri",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/kadin-plaj-cantasi-175",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/37493-erkek-kapri",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/225312-kadin-tisort",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/en/kadin-fermuarlilar-149",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/kadin-mayo-siyah-12014",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/kadin-mayo-lacivert-12011",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/kadin-plaj-aksesuarlari-164",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/unisex-plaj-giyim-163",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/38271-kadin-sweatshirt",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/38286-kadin-tayt",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/erkek-sapkalar-171",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/sort",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/esofman-alti",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/sweatshirt-erkek",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/sweatshirt",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tayt",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/kadin-bustiyer-bra",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/tayt-erkek",
    component: component_45stub1K29tF9kz4
  },
  {
    name: component_45stub1K29tF9kz4Meta?.name,
    path: "/esofman-alti-erkek",
    component: component_45stub1K29tF9kz4
  }
]